<template>
<div class="container" >
      <div class="row about-us-row">
		 <div class="col-sm-12 col-lg-12">
		 <center> <h2 class="iq-title">{{digitalInnovations.titleTop}}</h2> </center>
		 </div>
         <div class="col-sm-12 col-lg-12">
			   <center>
               <img width="50%" :src="digitalInnovations.imageTop.url" class="attachment-large size-large" alt="" loading="lazy" >
			   </center>
		 </div>
         <div class="col-sm-12 col-lg-12">
               <div class=" text-left iq-title-box iq-title-default">
               <p class="iq-title-desc">
                  {{digitalInnovations.descriptionTop}}
                  </p>
            </div>
         </div>
      </div>
      <br>
      <div class="row about-us-row text-center align-items-center">
		  <div class="col-sm-12 col-lg-12">
		 <center> <h2 class="iq-title">{{digitalInnovations.titleBottom}}</h2> </center>
		  </div>
		 <div class="col-sm-12 col-lg-12">
               <center><img width="50%" :src="digitalInnovations.imageBottom.url" class="attachment-large size-large" alt="" loading="lazy" > </center>
         </div>
         <div class="col-sm-12 col-lg-12">
            <div class=" text-left iq-title-box iq-title-default">
               <p class="iq-title-desc">
                  {{digitalInnovations.descriptionBottom}}
                  </p>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
  name: 'Innovations',
  props: {
    digitalInnovations: Object
  }
}
</script>
