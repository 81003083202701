<template>
	<main id="main" class="site-main">
		<Innovations :digitalInnovations="digitalInnovations" />
	</main>
</template>
<script>
import Innovations from "./Components/Projects/Innovations";

export default {
	name: "FastApps",
	components: {
		Innovations,
	},
	data() {
		return {
			digitalInnovations: [],
		};
	},
	async created() {
		// Get Data from the API

		this.digitalInnovations = data.digitalInnovations[0];
	},
};
</script>
